
import Layout from '../../components/Layout/Layout';
import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import './style.scss';
import CenterHeading from '../../components/Comman/CenterHeading';
import {WOW} from 'wowjs';

function Pricing() {

    const wow = new WOW({
    })
    wow.init();

    return(
        <Layout>
        <div className='Pricing_main'>
            <Container>
                <Row>
                    <Col>
                      <CenterHeading MainHeading="Pricing" 
                      MainDescription="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={3} className='wow fadeInUp' data-wow-delay="0.3s">
                      <div className='Pricing_box_main'>
                          <h4>Free</h4>
                          <div className='startingprice_text'>
                              <h5> &nbsp; </h5>
                              <h2>$0</h2>
                              <h5> &nbsp;  </h5>
                          </div>
                          <div className='listofplan_pricing'>
                              <p>Daily Requests: <span>50,000</span></p>
                              <p>Number of Projects: <span>3</span></p>
                          </div>
                          <Button className='btn btn_buynow'>Buy Now</Button>
                      </div>
                    </Col>
                    <Col md={6} lg={3} className='wow fadeInUp' data-wow-delay="0.6s">
                    <div className='Pricing_box_main Basic_plan'>
                          <h4>Basic</h4>
                          <div className='startingprice_text'>
                              <h5>Starting at</h5>
                              <h2>$19</h2>
                              <h5>Monthly</h5>
                          </div>
                          <div className='listofplan_pricing'>
                              <p>Daily Requests: <span>100,000</span></p>
                              <p>Number of Projects: <span>10</span></p>
                          </div>
                          <Button className='btn btn_buynow'>Buy Now</Button>
                      </div>
                    </Col>
                    <Col md={6} lg={3} className='wow fadeInUp' data-wow-delay="0.9s">
                    <div className='Pricing_box_main Professional_plan'>
                          <h4>Professional</h4>
                          <div className='startingprice_text'>
                              <h5>Starting at</h5>
                              <h2>$99</h2>
                              <h5>Monthly</h5>
                          </div>
                          <div className='listofplan_pricing'>
                              <p>Daily Requests: <span>500,000</span></p>
                              <p>Number of Projects: <span>50</span></p>
                          </div>
                          <Button className='btn btn_buynow'>Buy Now</Button>
                      </div>
                    </Col>
                    <Col md={6} lg={3} className='wow fadeInUp' data-wow-delay="1.2s">
                    <div className='Pricing_box_main Enterprise_plan'>
                          <h4>Enterprise</h4>
                          <div className='startingprice_text'>
                              <h5>Starting at</h5>
                              <h2>$499</h2>
                              <h5>Monthly</h5>
                          </div>
                          <div className='listofplan_pricing'>
                              <p>Daily Requests: <span>2,500,000</span></p>
                              <p>Number of Projects: <span>100</span></p>
                          </div>
                          <Button className='btn btn_buynow'>Buy Now</Button>
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Layout>
    )
}
export default Pricing;