import {Row, Col, Container, Button, Image, Form } from 'react-bootstrap';
import imgLogo from '../../Static/logobsn.svg';
import { BrowserRouter as Router,  Switch,  Route,  Link } from "react-router-dom";

import './style.scss';

function Registration() {
    return(
        <>
        <div className='account_main_reg'>
            <div className='account_innerbody_reg'>
                <div className='middle_accountcontant_reg'>
                    <div className='logo_account'>
                        <Image  src={imgLogo} alt="Logo" />
                    </div>
                    <div className='account_box'>
                        <h3>Create Account</h3>
                        <Form className='accountform'>
                            <Form.Group className="mb-3">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter username" />
                                <p className='info_form'>Username consists of 6-25 letters or numbers and needs to contain letters</p>
                            </Form.Group>

                            <Form.Group className="mb-3">
                            <Form.Label>Contry</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option>Select Contry</option>
                                <option value="1">India</option>
                                <option value="2">Australia</option>
                                <option value="3">Brazil</option>
                                <option value="3">United Kingdom</option>
                            </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Please enter real name" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number" placeholder="Please enter phone number" />
                            </Form.Group>

                            <Form.Group className="mb-3 verifiction_group">
                                <Form.Label>Verification code</Form.Label>
                                <Form.Control type="text" placeholder="" />
                                <Button  className='verify_btn'> Obtain SMS verification code </Button>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" placeholder="Please enter email address" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Personal Introduction</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder='Please fill in your personal introduction' />
                            </Form.Group>

                            <Form.Group className="mb-3 registraion_submit_information">
                                <p>
                                    System registration is an individual user by default. Individual users cannot publish or upload applications in the name of an enterprise. If you need to apply to become an enterprise user, please log in to Portal-User Center-Enterprise Authentication after completing the registration, and submit an application for enterprise real-name system authentication.
                                </p>
                            </Form.Group>  
 
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="I have read and agree to the Blockchain  Service Network Registration Agreement and  Privacy Protection Agreement" />
                                
                            </Form.Group>

                            <Button  className='account_btn'> Agree to terms and submit application </Button>
                            <p className='text-center mrbtm0'> Already have an account?   <Link className="CustomLinks" to="/login">Login</Link> </p>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Registration;