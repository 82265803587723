
import Layout from '../../components/Layout/Layout';
import {Row, Col, Container, Button, Image, Tabs, Tab } from 'react-bootstrap';
import {useState} from "react";
import './style.scss';
import Accordionfaq from '../../components/FAQ/Accordionfaq';
import {WOW} from 'wowjs';

function FAQpage() {
    const wow = new WOW({
    })
    wow.init();
    return(
        <Layout>
        <div className='FAQpage_main'>
            <Container>
                <Row>
                    <Col className='mainSection_dapps'>
                        <h3>FAQ</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className='FAQpageBody'>
                    <Tabs defaultActiveKey="General" id="uncontrolled-tab-example" className="tabs_faq">
                        <Tab eventKey="General" title="General" className='tabs_faq_bodypart' >
                            
                            <Accordionfaq Questionfaq="What is OPE?" 
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            <Accordionfaq eventKey="1" Questionfaq="What can you do with OPE?"   
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            <Accordionfaq eventKey="2" Questionfaq="What is OPE?"  
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />

                        </Tab>
                        <Tab eventKey="Development" title="Development"  className='tabs_faq_bodypart'>
                            
                            <Accordionfaq  Questionfaq="What can you do with OPE Devlopment?"    
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            <Accordionfaq eventKey="1" Questionfaq="What is OPE?"   
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            <Accordionfaq eventKey="2" Questionfaq="What can you do with OPE?"   
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            

                        </Tab>
                        <Tab eventKey="Pricing" title="Pricing"  className='tabs_faq_bodypart'>

                            <Accordionfaq  Questionfaq="What is the OPE Price?"  
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            <Accordionfaq eventKey="1" Questionfaq="What is OPE?" 
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            <Accordionfaq eventKey="2" Questionfaq="What can you do with OPE?"  
                            Answerfaq= "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)." 
                            />
                            

                        </Tab>
                    </Tabs>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Layout>
    )
}
export default FAQpage;