import {Container, Navbar, Nav, Image, NavDropdown } from 'react-bootstrap';
import { BrowserRouter as Router,  Switch,  Route,  Link, NavLink } from "react-router-dom";
import './style.scss';
import Logobsn from '../../Static/logobsn.svg';
import Search from './../Search/Search';
import Translation from './../Translation/Translation';
import { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';


function Header() {
const[active, setActive] = useState("");

const location = useLocation()



    return (
        <>
        <div className='bsn_header'>
        <Navbar expand="lg" className='bsn_navbar'>
        <Container>
            <Navbar.Brand href="/">
                <Image  src={Logobsn} alt="Logo" className='bsn_logo'/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
                <NavLink className="headerlinks" activeClassName={location.pathname === "/home"? "active" : ""} to="/">Home</NavLink>
                <NavDropdown title="OPE" id="basic-nav-dropdown" className={(location.pathname === "/ope" || location.pathname === "/faq") ? "active": ""} onClick={() =>setActive("ope")} >
                    <Link className="submenu_headerlinks" to="/ope">What is OPE?</Link>
                    <Link className="submenu_headerlinks" to="/registration">Sign Up</Link>
                    <Link className="submenu_headerlinks" to="/login">Login</Link>
                    <Link className="submenu_headerlinks" to="/projects">Projects</Link>
                    <Link className="submenu_headerlinks" to="/faq">FAQ</Link>
                </NavDropdown>
                <NavDropdown title="Developers" id="basic-nav-dropdown" className={(location.pathname === "/opetutorials" || location.pathname === "/ethereumtutorials") ? "active": ""} onClick={() =>setActive("opetutorials")}>
                    <Link className="submenu_headerlinks" to="/opetutorials">OPE Tutorials</Link>
                    <Link className="submenu_headerlinks" to="/ethereumtutorials">Ethereum Tutorials</Link>
                    <Link className="submenu_headerlinks" to="/ethereumdocumentation">Ethereum Documentation</Link>
                </NavDropdown>
                <NavLink className="headerlinks" activeClassName={location.pathname === "/pricing"? "active" : ""} to="/pricing">Pricing</NavLink>
                <NavLink className="headerlinks" activeClassName={location.pathname === "/dapps"? "active" : ""} to="/dapps">Dapps</NavLink>
                <NavLink className="headerlinks" activeClassName={location.pathname === "/contact"? "active" : ""} to="/contact">Contact us</NavLink>
                
            </Nav>
            <Search/>
            <Translation/>
            </Navbar.Collapse>
            
        </Container>
        </Navbar>
        </div>
        </>
       ) 
}
export default Header;