import Layout from '../../components/Layout/Layout';
import Detailswithimage from '../../components/Comman/Detailswithimage';
import Makeope from '../../components/Makeope/Makeope';
import FAQ from '../../components/FAQ/Faq';
import EthereumDevelopment from '../../Static/EthereumDevelopment.png';
import Getstartarrow from '../../Static/getstart_arrow.svg';
import Whatope from '../../components/WhatOPE/Whatope';
import ProjectsImage from '../../Static/Projects.png';
import OPETutorials from '../../Static/OPE_Tutorials.png';
import DrappsImage from '../../Static/DrappsImage.png';



function HomePage (){  
	return (
      <Layout>
		  <Detailswithimage 
		  heading = "Your Gateway to Ethereum Development"
		  description = "OPE offers a full Ethereum infrastructure where you can build your own decentralised applications faster, secure, and cheaper."
		  imgSource ={EthereumDevelopment}
		  type={2}
		  btnName="Get Started"
		  iconarrow={Getstartarrow}
		  />

		  <Makeope />
		  <Whatope />
		
		  <Detailswithimage 
		  heading = "Projects"
		  description = "Create your decentralized application on OPE. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
		  imgSource ={ProjectsImage}
		  btnName="Access to your OPE projects"		  
		  />

		  <Detailswithimage 
		  heading = "OPE Tutorials" 
		  description = "Learn step by step how to build dapps through tutorials." 
		  imgSource ={OPETutorials} 
		  type={1} 
		  btnName="Read tutorials now"		  
		  />

		  <Detailswithimage 
		  heading = "Ethereum Documentation"
		  description = "Get access the latest Ethereum official documentation.. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
		  imgSource ={ProjectsImage}
		  type={0}
		  btnName="Go to Ethereum documentation"		  
		  />

		  <Detailswithimage 
		  heading = "Dapps"
		  description = "A list of dapps running on OPE and available online. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
		  imgSource ={DrappsImage}
		  type={0}
		  btnName="Checkout the latest Dapps"		  
		  />


		  <FAQ />

	  </Layout>
	 )
}
export default HomePage;