import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import './style.scss';
import Makinglist from './Makinglist';
import CenterHeading from '../Comman/CenterHeading';
import {WOW} from 'wowjs';


import makeopeimageTop from '../../Static/img_top.png';
import makeopeimageMiddle from '../../Static/img_middle.png';
import makeopeimageBottam from '../../Static/img_bottam.png';

import IconFaster from '../../Static/iconFaster.svg';
import IconSecure from '../../Static/IconSecure.svg';
import IconCheaper from '../../Static/IconCheaper.svg';

function Makeope() {
    const wow = new WOW({
        // offset: 100,
        // mobile: false,
        // live: true
    })
    
    return (
        <>
        <Container>
            <CenterHeading 
            MainHeading= "What makes OPE different?" 
            MainDescription= "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." 
            />
            <Row className='align-item-center'>
            <Col md={6} className='align-item-center'>
                <div className='align-left leftimageWidth whatmakesleftimage'>
                       <div className='topImageWhat wow fadeInDown'> <Image  src={makeopeimageTop}  /> </div>
                       <div className='middleImageWhat '> <Image  src={makeopeimageMiddle}  /> </div>
                       <div className='bottamImageWhat wow fadeInUp'> <Image  src={makeopeimageBottam}  /> </div> 
                </div>
            </Col>
            <Col md={6}>
                <Makinglist 
                imageMakinglist={IconFaster} 
                MakingListHeading = "Faster" 
                MakingListDescription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry." 
                timerdelay="0.3s"
                />

                <Makinglist 
                imageMakinglist={IconSecure} 
                MakingListHeading = "Secure" 
                MakingListDescription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry." 
                timerdelay="0.6s"
                />

                <Makinglist 
                imageMakinglist={IconCheaper} 
                MakingListHeading = "Cheaper" 
                MakingListDescription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry." 
                timerdelay="0.9s"
                />
            </Col>
            </Row>
        </Container>
        
        </>
       ) 
}
export default Makeope;