import { Button } from 'react-bootstrap';
import {Image } from 'react-bootstrap';
import './style.scss';

function Buttons({btnName, iconarrow="", icon=0}) {
    return (
        <>
        <Button className="btn btn_bsn_blue" >{btnName} 
        {icon === 0 &&
        <Image src={iconarrow} />
        }
        </Button>
        </>
       ) 
}
export default Buttons;