import {Row, Col, Container, Image } from 'react-bootstrap';
import './style.scss';
import listicon from '../../Static/checkcircle.svg';
import CenterHeading from '../../components/Comman/CenterHeading';
import imgSourceOpeTools from '../../Static/metamaskimg.png';

function Opemigration() {
    return(
    <>
    <div className='main_migration'>
        <Container>
            <CenterHeading 
            MainHeading="Migrating an existing Dapp from Ethereum to OPE" 
            MainDescription="Below are some tips and advices to help you to migrate your existing decentralised application(s) from the public Ethereum network to OPE." 
            />
                <Row className="justify-content-md-center">
                    <Col md={9}>
                        <div className='opeleftside_contant leftside_contant'>
                            <h2  className='wow fadeInUp' data-wow-delay="0.3s">As an Enterprise</h2>
                            <p  className='wow fadeInUp' data-wow-delay="0.5s">
                            If your existing Dapp does ETH transactions (for doing payment) you can simply replace the usage of ETH by the usage of tokens instead. For example, ERC20 tokens can be used for payment purpose and can easily replace ETH. The logic of your application wouldn’t change. It stays exactly the same as before.
                            </p>

                            <h2 className='wow fadeInUp' data-wow-delay="0.5s">Interconnection between OPE and the public Ethereum</h2>
                            <p className='wow fadeInUp' data-wow-delay="0.6s">
                            Both OPE and Ethereum, although they are based on the exact same Ethereum technology, the two networks are distinct from each other and cannot transact directly. However, information can still be shared between the two networks by using third-party solutions such as Chainlink.
                            </p>

                            <h2  className='wow fadeInUp' data-wow-delay="0.6s">Interconnection between OPE and the public Ethereum</h2>
                            <div className='migrationImageList wow fadeInUp'   data-wow-delay="0.7s">
                                <ul>
                                    <li> <Image  src={imgSourceOpeTools} alt="Tools" /></li>
                                    <li> <Image  src={imgSourceOpeTools} alt="Tools" /></li>
                                    <li> <Image  src={imgSourceOpeTools} alt="Tools" /></li>
                                    <li> <Image  src={imgSourceOpeTools} alt="Tools" /></li>
                                    <li> <Image  src={imgSourceOpeTools} alt="Tools" /></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                   
                </Row>
        </Container>
    </div>
    </>
    );
}
export default Opemigration;