import {Row, Col, Container, Image } from 'react-bootstrap';
import './style.scss';
import listicon from '../../Static/checkcircle.svg';
import CenterHeading from '../../components/Comman/CenterHeading';

function Opewhatcan() {
    return(
    <>
    <div className='main_whatcan'>
    <Container>
        <CenterHeading 
        MainHeading="What can you do with OPE?" 
        MainDescription="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." 
        />
            <Row >
                <Col md={6}>
                    <div className='opeleftside_contant leftside_contant'>
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">As an Individual</h2>
                        <h3 className='wow fadeInUp' data-wow-delay="0.3s">Join the Ethereum community</h3>
                        <p className='wow fadeInUp' data-wow-delay="0.5s">
                            As an Individual in China as well as abroad, OPE allows you as a developer to be part to the Ethereum community to share your bright and interesting development ideas. The Ethereum community includes tens of thousands of developers, technologists, users and enthusiasts all over the world. This would help to grow the Ethereum’s popularity and influence in China.
                        </p>

                        <h3 className='wow fadeInUp' data-wow-delay="0.6s">Cost-effective development</h3>
                        <p className='wow fadeInUp' data-wow-delay="0.6s">
                            Traditional blockchain applications require creation and maintenance of node(s). Using OPE, you don’t have to purchase physical servers or cloud services for building your own Ethereum operating environment.
                        </p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='opeleftside_contant leftside_contant'>
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">As an Enterprise</h2>
                        <h3 className='wow fadeInUp' data-wow-delay="0.3s">Reach the Chinese market</h3>
                        <p className='wow fadeInUp' data-wow-delay="0.5s">
                            As an Enterprise, OPE can help to extend your new or existing decentralized Ethereum applications to the Chinese market. With your application available to Chinese customers, this opens doors to a whole new market and a huge opportunity to gain new customers.
                        </p>

                        <h3 className='wow fadeInUp' data-wow-delay="0.7s"> Reduce development costs</h3>
                        <p  className='wow fadeInUp' data-wow-delay="0.7s">
                            Both International and local Chinese enterprises can leverage a global blockchain infrastructure such as BSN to shifts from diversification to unification, complexity to simplicity and high-cost to low-cost. OPE could help in achieving the following objectives:
                        </p>
                        <div className='whatcanlisting wow fadeInUp' data-wow-delay="0.7s">
                            <p><span><Image  src={listicon} alt="Icon list" /></span> Simpler processes for development and maintenance of blockchain applications </p>
                            <p><span><Image  src={listicon} alt="Icon list" /></span> Reduction in costs of blockchain application development, deployment and maintenance </p>
                            <p><span><Image  src={listicon} alt="Icon list" /></span> Deploying blockchain applications to different cloud service at selected China location </p>
                            <p><span><Image  src={listicon} alt="Icon list" /></span> Authorizing users for accessing all applications with private keys </p>
                            <p><span><Image  src={listicon} alt="Icon list" /></span> Enabling developers for leveraging the OPE portal for deploying and managing their applications </p>
                        </div>
                    </div>
                </Col>
            </Row>
    </Container>
    </div>
    </>
    );
}
export default Opewhatcan;