import Layout from '../../components/Layout/Layout';
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { FaStar, FaRegStar } from 'react-icons/fa';
import DetailImage from '../../Static/detail_image.png';
import './style.scss';
import DetailSlider from './DetailSlider';

function Drappsdetails() {
    
    return(
    <Layout>
        <div className='drappsdetails_Main'>
        <Container>
            <Row>
                <Col md="8">
                    <div className='drappsdetails_inner'>
                        <div className='detailspage_image'><Image  src={DetailImage} alt="App Icon" /></div>
                        <div className='drapps_details_titles'>
                            <h3>Axa Infinity <span> Game</span></h3>
                            <div className='starIcons'><div className='staricon_table'><FaStar /> <FaStar /> <FaStar /> <FaRegStar/> <FaRegStar/> <p>3.0<span>8 Rating</span></p></div></div>
                        </div>
                    </div>
                    <div className='Contant_drappsdetails'>
                        <p> 
                            This blockchain game lets you form a team of monsters called Axies.
                        </p>
                        <p>
                            You can buy, sell, or trade them in the market. Your job is to create a strong line-up of Axies that will help you collect SLPs.
                        </p>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                        </p>
                    </div>

                    <div className='Review_drappsdetails'>
                        <div className='title_review_details'>
                            <h3>Reviews <span className='rating_points'>4.0/5.0   </span> <span className="Alltotal_review">  4 Ratings</span></h3>
                        </div>
                        <div className='ReviewList_drapps'>
                            <ul>
                                <li>
                                    <div className='Review_Mainbody_drapps'>
                                        <div className='Review_Firstname_drapps'><p>J</p></div>
                                        <div className='Review_descriptionRating_drapps'>
                                            <div className='Review_Rating_drapps'>
                                                <h5>Jhon <span className='star_review_rating'> <FaStar /> <FaStar /> <FaStar /> <FaRegStar/> <FaRegStar/> </span></h5>
                                            </div>
                                            <div className='Review_description_drapps'>
                                                <p>Very nice, entered with 0.5 bnb, thinking to reinvest my divs in one "fat" auction day ;)</p>
                                            </div>
                                        </div>
                                        <div className='Review_date_drapps'><p>Feb 16 , 2022</p></div>
                                    </div>
                                </li>
                                <li>
                                    <div className='Review_Mainbody_drapps'>
                                        <div className='Review_Firstname_drapps'><p>J</p></div>
                                        <div className='Review_descriptionRating_drapps'>
                                            <div className='Review_Rating_drapps'>
                                                <h5>Jhon <span className='star_review_rating'> <FaStar /> <FaStar /> <FaStar /> <FaRegStar/> <FaRegStar/> </span></h5>
                                            </div>
                                            <div className='Review_description_drapps'>
                                                <p>Very nice, entered with 0.5 bnb, thinking to reinvest my divs in one "fat" auction day ;)</p>
                                            </div>
                                        </div>
                                        <div className='Review_date_drapps'><p>Feb 16 , 2022</p></div>
                                    </div>
                                </li>
                                <li>
                                    <div className='Review_Mainbody_drapps'>
                                        <div className='Review_Firstname_drapps'><p>J</p></div>
                                        <div className='Review_descriptionRating_drapps'>
                                            <div className='Review_Rating_drapps'>
                                                <h5>Jhon <span className='star_review_rating'> <FaStar /> <FaStar /> <FaStar /> <FaRegStar/> <FaRegStar/> </span></h5>
                                            </div>
                                            <div className='Review_description_drapps'>
                                                <p>Very nice, entered with 0.5 bnb, thinking to reinvest my divs in one "fat" auction day ;)</p>
                                            </div>
                                        </div>
                                        <div className='Review_date_drapps'><p>Feb 16 , 2022</p></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>


                </Col>
                <Col md="4">
                    <div className='Vieapp'>
                        <Button className="btn_blue">View App</Button>
                    </div>
                    <div className='slider_details'>
                        <DetailSlider/>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    </Layout>
    )
}
export default Drappsdetails;