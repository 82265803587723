import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FaStar, FaRegStar, FaAppStoreIos, FaAndroid, FaDesktop } from 'react-icons/fa';
import {Link} from 'react-router-dom';

import './style.scss';


function Dappstablenew() { 

  const products = [
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"1",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"4", 
    "app_type":"Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"2",
    "app_name":"Axa Infinity", 
    "category":"DeFI", 
    "score":"3", 
    "app_type":"Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"3",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"5", 
    "app_type":"Apple Android Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"4",
    "app_name":"Axa Infinity", 
    "category":"DeFI", 
    "score":"3", 
    "app_type":"Android "
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"5",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"2", 
    "app_type":"Apple Android Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"6",
    "app_name":"Axa Infinity", 
    "category":"DeFI", 
    "score":"1", 
    "app_type":"Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"7",
    "app_name":"Axa Infinity", 
    "category":"DeFI", 
    "score":"5", 
    "app_type":"Apple Android Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"8",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"3", 
    "app_type":"Android "
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"9",
    "app_name":"Axa Infinity", 
    "category":"DeFI", 
    "score":"2", 
    "app_type":"Apple Android Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"10",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"1", 
    "app_type":"Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"11",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"2", 
    "app_type":"Apple Android Desktop"
    },
    {"prod_img":"https://digitalcontent.api.tesco.com/v2/media/ghs/eaa877ad-bf6e-4e75-9eb4-36bac01e9656/3350aafb-935e-455a-96cf-62749c9a1add_1852270143.jpeg?h=540&w=540", 
    "item_image":"", 
    "id":"12",
    "app_name":"Axa Infinity", 
    "category":"Game", 
    "score":"1", 
    "app_type":"Desktop"
    },
  ]

  const[product, setAllProduct] = useState(products)

  const itemFormatter = (cell, row) =><div className="item_img" > 
<img src={row.prod_img} alt="product_image"></img>  </div>;

const nameFormatter = (cell, row) =><div className="item_name" > 
<Link to="/dapps/drappsdetails">{row.app_name}</Link>  </div>;

const iconFormatter = (cell, row) =><div className="item_img staricon_table" > 
{row.score === "5" && <>
<FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></>}
{row.score === "4" && <>
<FaStar /><FaStar /><FaStar /><FaStar /><FaRegStar/></>}
{row.score === "3" && <>
<FaStar /><FaStar /><FaStar /><FaRegStar/><FaRegStar/></>}
{row.score === "2" && <>
<FaStar /><FaStar /><FaRegStar/><FaRegStar/><FaRegStar/></>}
{row.score === "1" && <>
<FaStar /><FaRegStar/><FaRegStar/><FaRegStar/><FaRegStar/></>}
</div>;

const appFormatter = (cell, row) =><div className="item_img Apptype_img" > 
{row.app_type=== "Apple Android Desktop" && 
<> <FaAppStoreIos/>  <FaAndroid/> <FaDesktop/></>}
{row.app_type=== "Apple Android " && 
<> <FaAppStoreIos/>  <FaAndroid/> </>}
{row.app_type=== "Apple" && 
<> <FaAppStoreIos/> </>}
{row.app_type=== "Android " && 
<> <FaAndroid/> </>}
{row.app_type=== "Desktop" && 
<> <FaDesktop/></>}
</div>;

  const columns = [
    { dataField: 'id', text: '#',classes: 'light_text', 
      headerStyle: (colum, row) => {
      return { width: '30px' }
    }},
    { dataField: 'item_image', text: '', sort: false,  classes: 'dark_text', formatter: itemFormatter, 
    headerStyle: (colum, row) => {
      return { width: '60px' }
    }
  },
    { dataField: 'app_name', text: 'App Name', sort: false, classes: 'light_text', formatter: nameFormatter,
    headerStyle: (colum, row) => {
      return { minWidth: '40%' }
    } },
    { dataField: 'category', text: 'Category', sort: true, classes: 'light_text',
    headerStyle: (colum, row) => {
      return { width: '130px' }
    } },
    { dataField: 'score', text: 'Score', sort: false, classes: 'light_text', formatter: iconFormatter,
    headerStyle: (colum, row) => {
      return { width: '150px' }
    } },
    { dataField: 'app_type', text: 'App Type', sort: false, classes: 'light_text', formatter: appFormatter,
    headerStyle: (colum, row) => {
      return { width: '150px' }
    } },
  ];

//   const selectRow = {
//     mode: 'checkbox',
//     clickToSelect: true
//   };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: false,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const gameSearchHandle= () =>{
    let search = "game"
    let dataArray:any = [];
    console.log("aa")
    products.forEach((element,index) => {      
        if(((element['category']+"").toLowerCase()).includes(search) ){
          dataArray.push(element); 
        }       
      setAllProduct(dataArray);
    });          
  }

  const allSearchHandle= () =>{
    setAllProduct(products);
  }

  
  const defySearchHandle= () =>{
    let search = "defi"
    let dataArray:any = [];
    console.log("aa")
    products.forEach((element,index) => {      
        if(((element['category']+"").toLowerCase()).includes(search) ){
          dataArray.push(element);
        }        
        setAllProduct(dataArray);
    });          
  }

 // const { SearchBar } = Search;
//   const MyExportCSV = (props) => {
//     const handleClick = () => {
//       props.onExport();
//     };

  return (
    <>     
            <div className='Dappstopbar'>
                <ul>
                    <li className='active' onClick={allSearchHandle}>All</li>
                    <li onClick={gameSearchHandle}>Game</li>
                    <li onClick={defySearchHandle}>DeFi</li>
                </ul>
            </div>
      <ToolkitProvider
        bootstrap4
        keyField='batch_no'
        data={product}
        columns={columns}     
        search      
        exportCSV
      
      >
        {
          props => (
            <div className= "table--main">              
             {/* <SearchBar { ...props.searchProps } /> */}
              <BootstrapTable
                // selectRow={ selectRow }
                pagination={pagination}
                {...props.baseProps}                
                className='tree-table table-responsive'
                bordered={ false }
                striped
                
              />
            
            </div>
          )
        }
      </ToolkitProvider>      
    </>
  );

  
}

export default Dappstablenew;