import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import Opelist from './Opelist';
import './style.scss';
import {WOW} from 'wowjs';

function Opecontant({Opeheading, Opedescription="", type=0, imgSourceOpe=""}) {
    const wow = new WOW({
    })
    wow.init();
    return(
    <>
    <div>
    <Container>
            <Row className='align_item_center'>
                <Col md={6}>
                    <div className='opeleftside_contant leftside_contant'>
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">{Opeheading}</h2>
                        <p className='wow fadeInUp' data-wow-delay="0.4s">{Opedescription}</p>
                        {type=== 1 &&
                        <div className='wow fadeInUp' data-wow-delay="0.4s">
                            <Opelist Opelistcontant="OPE is a public Ethereum blockchain hosted and maintained by BSN."/>
                            <Opelist Opelistcontant="OPE is an Ethereum network but different compared to the public Ethereum network. The OPE blockchain is initiated with a different genesis block and a specific Network ID."/>
                            <Opelist Opelistcontant="OPE is only available in the BSN network in China."/>
                            <Opelist Opelistcontant="OPE is known as “Wuhan chain” in BSN China."/>
                            <Opelist Opelistcontant="OPE blockchain network type is “Permissioned” because an user cannot add its own Ethereum node to the OPE blockchain network."/>
                            <Opelist Opelistcontant="OPE and the public Ethereum network are two networks distinct from each other and cannot transact directly."/>
                            <Opelist Opelistcontant="OPE uses the Proof Of Authority (POA) consensus. The POA mechanism provides high performance and fault tolerance. In PoA, rights to generate new blocks are awarded to nodes that have proven their authority to do so."/>
                            <Opelist Opelistcontant="In OPE blockchain, ETH transactions between users are blocked. The following transactions are not possible."/>
                        </div>
                        }
                        {type=== 1 &&
                        <div className='contantlist_titles'>
                            <p>Transfer ETH from a user wallet address to another user wallet address</p>
                            <p>Transfer ETH from a user wallet address to a Smart Contract and vice-versa.</p>
                            <p>Transfer ETH from a Smart Contract to another Smart Contract.</p>
                        </div>
                        }
                        
                    </div>
                </Col>
                <Col md={6}>
                    <div className='align-right imageRightsideDetail'>
                        <Image  src={imgSourceOpe} alt="Ethereum Development" />
                    </div>
                </Col>
            </Row>
    </Container>
    </div>
    </>
    );
}
export default Opecontant;