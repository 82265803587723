import {Row, Col, Container, Button, Image, Form } from 'react-bootstrap';
import imgLogo from '../../Static/logobsn.svg';
import { BrowserRouter as Router,  Switch,  Route,  Link, useHistory } from "react-router-dom";
import './style.scss';
import { useState, useEffect } from 'react';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    LoadCanvasTemplateNoReload,
    validateCaptcha
  } from "react-simple-captcha";

function Login() {
    const[username, setUserName] = useState("");
    const[password, setPassword] = useState("");
    const[captcha,setCaptcha] = useState("");
    const[error, setError] = useState(false);
    const[usernameerror, setUsernameerror] = useState(false);

    const history = useHistory();

    const onSubmitandler =(e) =>{
        let user_captcha = captcha;
        if(username === "" || password ===""){
            //  alert("Please enter usename/password")
             setUsernameerror(true)
        }else{
            console.log("username", username)
            console.log("password", password)
            if (validateCaptcha(user_captcha) == true) {               
                loadCaptchaEnginge(6);
                setCaptcha("") 
                history.push('/home');
                } else {
                setError(true)
                setCaptcha("") 
                }            
        }         
       
    }

 
    useEffect(() => {
        loadCaptchaEnginge(8);
      }, []);
    

    return(
        <>
        <div className='account_main'>
            <div className='account_innerbody'>
                <div className='middle_accountcontant'>
                    <div className='logo_account'>
                        <Image  src={imgLogo} alt="Logo" />
                    </div>
                    <div className='account_box'>
                        <h3>Login To OPE</h3>
                        <Form className='accountform'>
                            <Form.Group className="mb-3">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter username" onChange={(e) => setUserName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                            </Form.Group>

                            {usernameerror && <p className='errormessgae'>Please Enter Valid Username and Password</p>}

                            <Form.Group className="mb-3 cehckboxandforgot" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Remember Me" />
                                <Link className="CustomLinks" to="/forgotpassword">Forgot Password? </Link>
                            </Form.Group>
                            
                            <div className="row form-group captcha_login">
                                <div className="col-md-6">
                                <div>
                                    <label>Please Enter captcha code</label>
                                    <input
                                    placeholder="Enter Captcha"
                                    id="user_captcha_input"
                                    name="user_captcha_input"
                                    type="text"
                                    onChange={(e) => setCaptcha(e.target.value)}
                                    ></input>                                    
                                </div>
                                {error && <p className='errormessgae'>Enter valid capcha code</p>}
                                </div>

                                <div className="col-md-6 ex_captcha">
                                <LoadCanvasTemplate />
                                </div>      
                            </div>
                           

                            <Button className='account_btn' onClick={onSubmitandler}> Login </Button>
                            <p className='text-center mrbtm0'> Don't have an account?  <Link className="CustomLinks" to="/registration">Sign Up</Link> </p>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Login;