import Layout from '../../components/Layout/Layout';
import './style.scss';
import {Row, Col, Container, Button, Image , Modal, Form} from 'react-bootstrap';
import Dappstablenew from './Dappstablenew';
import Dappstable from './Dappstable';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPEG", "PNG", "GIF", "TXT", "PDF"];

    


function Dapps() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    return(
        <Layout>
           <div className='Main_dapps'>
               <Container>
                    <Row>
                        <Col className='mainSection_dapps'>
                            <h3>Dapps</h3>
                            <Button className="btn_blue" onClick={handleShow}>Publish my Dapp</Button>
                        </Col>
                    </Row>
                    <Row>
                        <div className='Dapps_table_box'>
                            <Dappstablenew/>
                            {/* <Dappstable/> */}
                        </div>
                    </Row>
               </Container>

               <Modal show={show} onHide={handleClose} className='publish_dapp_model'>
                    <Modal.Body>
                        <Form>
                            <Row className="mb-3">
                            <Form.Label>Name<span className='red_star'>*</span></Form.Label>
                            <Form.Group as={Col} className="publish_namefield">
                                <Form.Control type="text" placeholder="Enter First Name" />
                            </Form.Group>
                            <Form.Group as={Col} className="publish_namefield">
                                <Form.Control type="text" placeholder="Enter Last Name" />
                            </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email<span className='red_star'>*</span></Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Company</Form.Label>
                                <Form.Control type="text" placeholder="Enter Company Name" />
                            </Form.Group>
                            
                            <Form.Group className="mb-3">
                                <Form.Label>Dapp Name<span className='red_star'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter Dapp Name" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Category<span className='red_star'>*</span></Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option>Open this select menu</option>
                                    <option value="1">Game</option>
                                    <option value="2">Sports</option>
                                    <option value="3">Payments</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>IOS</Form.Label>
                                <Form.Control type="text" placeholder="Enter IOS App Name" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>WebApp</Form.Label>
                                <Form.Control type="text" placeholder="Enter WebApp URL" />
                            </Form.Group>

                            <Row className="mb-3 Upload_logo_image">
                            <Form.Group as={Col} className='LogoUpload_leftside'>
                                <Form.Label>Logo<span className='red_star'>*</span> <span className='gray_color'>(300x300)</span> </Form.Label>
                                <Form.Control type="file" />
                            </Form.Group>

                            <Form.Group as={Col} className="LogoUpload_Rightside">
                                <Form.Label>Supporting Images <span className='gray_color'>(1280x800)</span> </Form.Label>
                                
                                <FileUploader  multiple={true} handleChange={handleChange}
                                 name="file" types={fileTypes}
                                />
                                {/* <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p> */}
                            </Form.Group>
                            </Row>


                            <Form.Group className="" >
                                <Form.Label>Description<span className='red_star'>*</span></Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder='No more than 1024 words' />
                            </Form.Group>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-cancel' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className='btn btn-publish' onClick={handleClose}>
                            Publish
                        </Button>
                    </Modal.Footer>
                </Modal>

           </div>
        </Layout>
    )
}
export default Dapps;