import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import imgwhatope from '../../Static/Computer.png';
import bgWhatope from '../../Static/bg-whatope.png';
import './style.scss';

function Whatope() {
    return(
        <>
        <div className='whatope_main'>
            {/* <div className='background_image_ope'><Image  src={bgWhatope} alt="what OPE Computer" /></div> */}
        <Container>
            <Row>
                <Col md={6} className='align_item_center'>
                    <div className="leftcontant_whatope">
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">What is OPE ?</h2>
                        <p className='wow fadeInUp' data-wow-delay="0.4s">
                            OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies.
                        </p>
                        <div className='wow fadeInUp' data-wow-delay="0.5s" >
                            <Button className="btn btn_bsn_white">  Learn more about OPE </Button>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='rightsideimage_whatope text-center imageRightsideDetail'>
                        <div className='wow fadeInUp' data-wow-delay="0.5s"> 
                            <Image  src={imgwhatope} alt="what OPE Computer" />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    )
}
export default Whatope;