import {useState} from "react";
import {Carousel} from 'react-bootstrap';
import SliderImage from '../../Static/slider_image.png';

function DetailSlider(){

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    };


    return(
    <>
     <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={SliderImage}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={SliderImage}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={SliderImage}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </>
    )
}
export default DetailSlider;
