
import Layout from '../../components/Layout/Layout';
import {Row, Col, Container, Button, Image, Form } from 'react-bootstrap';
import './style.scss';
import CenterHeading from '../../components/Comman/CenterHeading';
import ContactIcon from '../../Static/ContactIcon.svg';
import { useEffect, useState } from 'react';
import {WOW} from 'wowjs';

function Contact() {

    // const wow = new WOW({
    // })
    // wow.init();

    const[sendmessage, setSendmessage] = useState();
    const[username, setUsername] = useState("");
    const[email, setEmail]= useState("");
    const[contacterror, setContacterror] = useState(false);
    const[loader, setLoader] = useState(false);
    const[thankyou, setThankyou] = useState(false);


    const handlesendmessage = (e) => { 
                   
        setSendmessage(sendmessage);   
        setLoader(true);     
        if (username === "" || email ==="") {
            setContacterror(true);
           
        }else{
            console.log("username", username);
            setContacterror(false);
            setThankyou(true);
        }
    }
    useEffect(()=>{
        setTimeout(() => {
            setLoader(false);
            setThankyou(false);
        }, 2000);
    },[])
    return(
        <Layout>
        <div className='Contactpage_main'>
            <Container>
                
                <Row>
                    <Col>
                      <CenterHeading MainHeading="Contact Us" 
                      MainDescription="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." />
                    </Col>
                </Row>
                <Row className='Contactform_box'>
                {/* {loader && <p>Loader</p>} */}

                {thankyou && <>
                   <div className='text-center Thankyoumessage'> <h4>Thank you for the message. We will contact you soon.</h4></div>
                </>}
                    <Col md={6}>
                       <div className='Image_animation wow fadeInUp' data-wow-delay="0.3s"> <Image  src={ContactIcon} alt="Contact Icon" /></div>
                    </Col>
                    <Col md={6}>

                    <Form  className='contactform_main wow fadeInUp' data-wow-delay="0.3s">
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Enter Username" onChange={(e) => setUsername(e.target.value)} />
                            {contacterror && <p className='errormessgae'>Please Enter Username</p>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                            {contacterror && <p className='errormessgae'>Please Enter Email</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Write Description"/>
                        </Form.Group>
                        <Button className='account_btn'  onClick={handlesendmessage}> Send Message </Button>
                    </Form>
                     
                    </Col>
                   
                </Row>
            </Container>
        </div>
        </Layout>
    )
}
export default Contact;