function CenterHeading({MainHeading, MainDescription}) {
    return(
        <>
        <div className="Center_heading text-center wow zoomIn" data-wow-delay="0.3s">
            <h2> {MainHeading} </h2>
            <p> {MainDescription} </p>
        </div>
        </>
    )
}

export default CenterHeading;