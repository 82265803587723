import React,{useState} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { UserContext } from './lib/UserContext';
import './App.css';
import HomePage from "./Pages/Home/HomePage";
import OPEPage from './Pages/OPE/Opepage';
import Login from './Pages/Login/Login';
import Registration from './Pages/Registration/Registration';
import opetutorials from './Pages/Tutorial/TutorialOPE';
import Dapps from './Pages/Dapps/Dapps';
import Drappsdetails from './Pages/Dapps/Drappsdetails';
import FAQpage from './Pages/FAQ/FAQpage';
import Pricing from './Pages/Pricing/Pricing';
import Contact from './Pages/Contact/Contact';

function App() {
	const [loginUser, setLoginUser] = useState(null);
  return (
    <UserContext.Provider value={[loginUser, setLoginUser]}>
    <Router>
      <Switch>
          <Route
          exact
          path="/"
          render={() => <Redirect to="/home" />}
        />
        <PublicRoute path="/home" component={HomePage} />
        <PublicRoute path="/ope" component={OPEPage} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/registration" component={Registration} />
        <PublicRoute path="/opetutorials" component={opetutorials} />
        <PublicRoute exact path="/dapps/:id" component={Drappsdetails} />
        <PublicRoute path="/dapps" component={Dapps} />        
        <PublicRoute path="/faq" component={FAQpage} />  
        <PublicRoute path="/pricing" component={Pricing} />  
        <PublicRoute path="/contact" component={Contact} />  

       </Switch>
    </Router>
	 </UserContext.Provider>
  );
  
  function PublicRoute({ component, ...rest } : any) {
    return (
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  function PrivateRoute({ component, ...rest } : any) {
    return (
	localStorage.getItem('checkLogin') === 'solpulseadmin' ?
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
	  :
	  <Redirect to="/login" />
	  
    );
  }
}

export default App;
