import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import './style.scss';
import CenterHeading from '../Comman/CenterHeading';
import Accordionfaq from './Accordionfaq';

function FAQ() {
    return(
     <>
     <div className='FAQSECTION'>
      <Container>
            <CenterHeading 
            MainHeading= "Frequently Asked Questions" 
            MainDescription= "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." 
            />
            <Row>
            <Col>
               <div className='faq_main_section'>
                <Accordionfaq 
                Questionfaq = "What is OPE?" 
                Answerfaq = "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps). " 
                timerdelay="0.6s"
                />

                <Accordionfaq eventKey="1"
                Questionfaq = "What can you do with OPE?" 
                Answerfaq = "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps). " 
                timerdelay="0.8s"
                />

                <Accordionfaq  eventKey="2"
                Questionfaq = "What can you do with OPE?" 
                Answerfaq = "OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps). " 
                timerdelay="1s"
                />
               </div>
            </Col>
            </Row>
        </Container>
    </div>
     </>
    )
}
export default FAQ;