import Layout from '../../components/Layout/Layout';
import { Row, Col, Button, Image, Nav, Tab, Form } from 'react-bootstrap';
import './style.scss';
import SearchIcon from '../../Static/searchicon.svg';
import Tabsmainheading from './Tabsmainheading';
import Tabcotantdetails from './Tabcotantdetails';
import ArrowIconnext from '../../Static/arrow_next.svg';
import ArrowIconprev from '../../Static/arrow_prev.svg';
import { useState, useEffect } from 'react';


function TutorialOPE() {
    var datatutorials =[
        {
            "id":0,
            "name":"Introduction",
        },
        {
            "id":1,
            "name":"Non-Fungible Token (ERC-721 and ERC-1155)",
        },
        {
            "id":2,
            "name":"Getting Started with the Required Setup",
        },
        {
            "id":3,
            "name":"Storing files in Pinata IPFS Cloud",
        },
        {
            "id":4,
            "name":"Creating the Smart Contract",
        },
        {
            "id":5,
            "name":"Testing the Smart Contract",
        },
        {
            "id":6,
            "name":"Deploying the Smart Contract to the local Ganache Blockchain",
        },
        {
            "id":7,
            "name":"Minting the Tokens",
        },
        {
            "id":8,
            "name":"Creating the Client-Side Application",
        },
        {
            "id":9,
            "name":"Running the project locally",
        },
        {
            "id":10,
            "name":"Demo",
        },
        {
            "id":11,
            "name":"Moving from Ganache to OPE Blockchain",
        },
    ]
    const [activeTab, setActiveTab] = useState(0);
    const[items, setItems] = useState(datatutorials);    

    const handleSearchItems = (searchValue) => {        
        let search = searchValue.toLowerCase()
        let dataArray:any = []
        datatutorials.forEach((element, index) => {           
            if((element['name']).toLowerCase().includes(search)){
                dataArray.push(element)
            }          
        });         
        setItems(dataArray)                 
    }
      

    return (
        <Layout>
            <div className='main_TutorialOPE'>
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className='maintab_lisitngbar'>
                        <Col sm={3} className='leftside_headingtitle'>
                            <Form className="searchtopic_tutorials">
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Search Topic" onChange={(e) =>handleSearchItems(e.target.value)}/>
                                    <Button className='search_btn'><Image src={SearchIcon} alt="Searchicon" /></Button>
                                </Form.Group>
                            </Form>


                            <Nav variant="pills" className="flex-column leftsidetabs">
                            
                                {items && items.map((val, index) => {
                                    return <>
                                        <Nav.Item>
                                            <Nav.Link eventKey={val.id} onClick={() => setActiveTab(val.id)}>{val.name}</Nav.Link>
                                        </Nav.Item>                                       
                                    </>
                                })}                      

                            </Nav>
                        </Col>
                        <Col sm={9} className='righside_contantbar'>

                            <Tab.Content>
                                <Tab.Pane eventKey="0">
                                    <Tabsmainheading
                                        Heading="Introduction"
                                        Description="The following is a simple tutorial to teach you how to create a simple decentralized application or dapp involving Non-Fungible Tokens (NFT)."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="1">
                                    <Tabsmainheading
                                        Heading="Non-Fungible Token (ERC-721 and ERC-1155)"
                                        Description="Non-fungible tokens (NFTs) are gaining popularity in the gaming and collectible space, and it is one of the latest booming areas of the crypto industry. "
                                        Description2="NFTs can be created and managed by different smart contract standard such as ERC-721 and ERC-1155."
                                    />
                                    <Tabcotantdetails
                                        Subheading="ERC-721"
                                        Subdescription="The ERC-721 token standard represents a single unique asset that cannot be interchanged or divided. Each ERC-721 token holds its own unique contract values, such as a piece of art, or ownership and identity details of a tokenized real-world asset such as a house. Though there is complete flexibility in the creation of a token, ERC-721s are solid in terms of immutability, transparency of ownership, and security. In being unique, ERC-721 tokens are commonly referred to non-fungible tokens (NFTs)." />

                                    <Tabcotantdetails
                                        Subheading="ERC-1155"
                                        Subdescription="The ERC-721 standard is great for tokenizing and creating unique assets that can be transferred from one wallet to another. However, managing a collection of many ERC-721 tokens can be inefficient and costly in gas fee. For example, if you want to transfer multiple user objects in a game for a particular character. This is where ERC-1155 comes in. It can allow batch transfers of multiple tokens at once which is much more efficient and cheaper than to transfer multiple objects one by one with an ERC-721. ERC-1155 tokens are often described as a “next-generation multi-token standard and support both non-fungible tokens as well as fungible tokens."
                                        Subdescription2="In the following tutorial, the decentralized application will use an ERC-1155 smart contract."
                                    />

                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                    <Tabsmainheading
                                        Heading="Getting Started with the Required Setup"
                                        Description="The following is a simple tutorial to teach you how to create a simple decentralized application or dapp involving Non-Fungible Tokens (NFT)."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">
                                    <Tabsmainheading
                                        Heading="Storing files in Pinata IPFS Cloud"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="4">
                                    <Tabsmainheading
                                        Heading="Creating the Smart Contract"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="5">
                                    <Tabsmainheading
                                        Heading="Testing the Smart Contract"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="6">
                                    <Tabsmainheading
                                        Heading="Deploying the Smart Contract to the local Ganache Blockchain"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="7">
                                    <Tabsmainheading
                                        Heading="Minting the Tokens"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="8">
                                    <Tabsmainheading
                                        Heading="Creating the Client-Side Application"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="9">
                                    <Tabsmainheading
                                        Heading="Running the project locally"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="10">
                                    <Tabsmainheading
                                        Heading="Demo"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="11">
                                    <Tabsmainheading
                                        Heading="Moving from Ganache to OPE Blockchain"
                                        Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    />
                                </Tab.Pane>
                            </Tab.Content>

                            <div className='NextPrevbtn' >
                                {activeTab > 0 &&
                                    <div className='PrevMain' onClick={(e) => { 
                                        if (activeTab === 0) { return; } else{
                                        setActiveTab(activeTab - 1);  
                                        setItems(datatutorials)  }
                                         }}>
                                        <div className='iconTabs'><Image src={ArrowIconprev} alt="Searchicon" /></div>
                                        <div className='tabslistbtns'>
                                            <h4>Previous</h4>
                                            <p>{datatutorials[activeTab].id !== 0 && datatutorials[(activeTab - 1)].name}</p>
                                        </div>
                                    </div>
                                }
                                {activeTab <= 10 &&
                                    <div className='NextMain' onClick={(e) => { 
                                        if (activeTab > 11) { return; } 
                                        setActiveTab(activeTab + 1) 
                                        setItems(datatutorials) }}>
                                        <div className='tabslistbtns'>
                                            <h4>Next</h4>
                                            <p>{datatutorials[(activeTab + 1)].name}</p>
                                        </div>
                                        <div className='iconTabs'><Image src={ArrowIconnext} alt="Searchicon" /></div>
                                    </div>
                                }
                            </div>

                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </Layout>
    )
}
export default TutorialOPE;