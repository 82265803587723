import Layout from '../../components/Layout/Layout';
import {Row, Col, Button } from 'react-bootstrap';
import CenterHeading from '../../components/Comman/CenterHeading';

import Opetopbanner from '../../Static/opetopbanner.png';
import Opedifferent from '../../Static/ope_different.png';

import Opecontant from '../OPE/Opecontant';
import Opewhatcan from './Opewhatcan';
import Opemigration from './Opemigration';

function OPEPage (){  
return(
    <Layout>
        <div className='main_what_is_ope'>
        <CenterHeading 
            MainHeading= "Introduction " 
            MainDescription= "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout." 
        />

        <Opecontant 
            Opeheading="What is OPE?" 
            Opedescription="OPE (Open Permissioned Ethereum) is a service providing access to Ethereum blockchain networks (Mainnet and Testnet) on BSN China (Blockchain-based Service Network). BSN is a global infrastructure network based on blockchain technologies. The network runs across different cloud platforms, portals and frameworks in order to offer developers and enterprises easier and cost-effective development, deployment and management of permissionless and permissioned nodes, implementation of decentralized applications (dapps)."
            imgSourceOpe={Opetopbanner}
        />
        
        <div className='padding_ope'>
        <Opecontant 
            Opeheading="How OPE different than Ethereum?" 
            imgSourceOpe={Opedifferent}
            type={1}
        />
        </div>

        <Opewhatcan/>
        <Opemigration/>
        </div>
        
    </Layout>
)
}
export default OPEPage;